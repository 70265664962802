<template>
	<div>
		<van-cell-group class="from-group-item" style="margin-bottom: 10px;">
			<van-collapse v-model="collapse_address" accordion>
				<van-collapse-item title="填写收货信息" :value="address==''||name==''||phone==''?'未填写':'已填写'" required name="1">
					<van-field v-model="name" autosize required type="input" label="收货人:" placeholder="请填写收货人" />
					<van-field v-model="phone" autosize required type="input" label="收货人电话:" placeholder="请填写收货人电话" />
					<van-field v-model="address" autosize required type="input" label="收货地址:" placeholder="请填写收货地址" />
				</van-collapse-item>
			</van-collapse>
		</van-cell-group>

		<van-cell-group class="from-group-item" style="margin-bottom: 10px;">
			<div class="goods-container">
				<div class="goods-wrap">
					<van-cell title-class="top-cell flex flex-row">
						<template slot="title">

							<van-image width="3rem" height="3rem" :src="img" fit="cover">
								<template v-slot:loading>
									<van-loading type="spinner" size="20" />
								</template>
							</van-image>


							<div class="text-wrap">
								<div style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;width: 6rem">
									<span class="text-theme1" style="font-size: 14px;">{{goods_info.good_name}}</span>
								</div>
								<div class="desc_wrap">
									<span>{{goods_info.description}}</span>
								</div>
							</div>

						</template>
					</van-cell>
				</div>
			</div>
		</van-cell-group>

		<van-cell-group class="from-group-item" style="margin-bottom: 10px;">
			<van-cell title="购买数量:">
				<van-stepper label="购买数量:" v-model="num" :step="1" :min="1" />
			</van-cell>
			<van-field :value="type==0?'￥' + goods_info.alone_price:'￥' + goods_info.groupbuy_price" error input-align="right"
			 autosize label="单价:" type="text" />
		</van-cell-group>

		<div class="from-group-item group-detail-bottom">
			<div class="group-detail-bottom-item">
				<span>总付款：</span>
				<span style="color:#ff9b19;font-size: 14px;">￥{{totalprice}}</span>
			</div>
			<div class="group-detail-bottom-button" style="background-color: #fe0000;" @click="pay()">
				<div>立即支付</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		Field,
		Row,
		Col,
		Cell,
		CellGroup,
		Picker,
		Popup,
		DatetimePicker,
		Uploader,
		SwitchCell,
		Checkbox,
		Button,
		Toast,
		Stepper,
		Slider,
		Collapse,
		CollapseItem,
		Loading
	} from "vant";
	import {
		activity_detail,
		groupbuy_user_getgoodsdetail,
		groupbuy_user_createorder,
		groupbuy_user_buildorder,
		live_order_pay,
	} from '../../../../request/api.js'
	import wx from "@/request/utils/wechat";
	export default {
		components: {
			[Field.name]: Field,
			[Row.name]: Row,
			[Col.name]: Col,
			[Cell.name]: Cell,
			[CellGroup.name]: CellGroup,
			[Picker.name]: Picker,
			[Popup.name]: Popup,
			[DatetimePicker.name]: DatetimePicker,
			[Uploader.name]: Uploader,
			[SwitchCell.name]: SwitchCell,
			[Checkbox.name]: Checkbox,
			[Button.name]: Button,
			[Stepper.name]: Stepper,
			[Slider.name]: Slider,
			[Collapse.name]: Collapse,
			[CollapseItem.name]: CollapseItem,
			[Loading.name]: Loading
		},
		data() {
			return {
				collapse_address: ['1'],
				commit_loading: false,
				address: '',
				name: '',
				phone: '',
				num: 1,
				goods_id: '',
				activity_id: '',
				type: 0,
				img: '',
				goods_info: {},
				group_id: 0
			}
		},
		computed: {
			totalprice() {
				if (this.goods_info) {
					if (this.type == 0) {
						return this.goods_info.alone_price * this.num
					} else {
						return this.goods_info.groupbuy_price * this.num
					}
				}
			}
		},
		mounted() {
			this.num = this.$route.query.num
			this.goods_id = this.$route.query.id
			this.activity_id = this.$route.query.activity_id
			this.type = this.$route.query.type
			this.group_id = this.$route.query.group_id
			this.loadData()
		},
		methods: {
			loadData() {
				groupbuy_user_getgoodsdetail({
					goods_id: this.goods_id
				}).then(res => {
					if (res.code == 1) {
						this.goods_info = res.data;
						this.img = res.data.images[0]
					} else {
						this.$dialog({
							title: '提示',
							message: res.msg
						}).then(g => {
							this.$router.go(-1);
						});
					}
				}).catch(error => {
					this.$dialog({
						title: '提示',
						message: "访问出错"
					}).then(g => {
						this.$router.go(-1);
					});
				});
			},
			pay() {
				if (this.name == '') {
					this.$dialog({
						message: '请填写收货人'
					})
					return false;
				}
				if (!(/^1[3456789]\d{9}$/.test(this.phone))) {
					this.$dialog({
						message: '手机号码有误'
					})
					return false;
				}
				if (this.address == '') {
					this.$dialog({
						message: '请填写收货地址'
					})
					return false;
				}
				if (this.goods_info.stock == 0 || this.goods_info.stock < 1) {
					this.$dialog({
						message: '商品已被抢完'
					})
					return false
				}
				if (this.num > this.goods_info.stock) {
					this.$dialog({
						message: '购买数量超过商品现有库存'
					})
					return false
				}
				let _this = this
				this.$dialog({
					message: '确定要支付订单吗？'
				}).then(e => {
					this.showLoading("下单中...", true);
					// 创建订单
					groupbuy_user_createorder({
						activity_id: this.activity_id,
						goods_id: this.goods_id,
						group_id: this.group_id,
						number: this.num,
						is_groupbuy: this.type,
						receiver_name: this.name,
						receiver_address: this.address,
						receiver_mobile: this.phone
					}).then(res => {
						_this.hideLoading();
						if (res.code == 1){
							_this.$router.replace({
								path: '/payment/method',
								query: {
									id: res.data.id,
									type: 'group'
								}
							})
						}else{
							_this.$dialog({
								message: res.msg
							})
						}
						
					})
				}).catch(err => {
					// 弹出框
					console.log(err);
				})
			}
		}
	}
</script>

<style scoped lang="less">
	.desc_wrap {
		width: 6rem;
		height: 3rem;
		overflow: hidden;
		text-overflow: ellipsis;
		line-height: 0.5rem;
		color: gray;
		font-size: 12px;
	}

	// 公共商品样式
	.goods-wrap {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		background-color: white;
		font-size: 0;
		overflow: hidden;

		.van-image {
			margin-right: 10px;
			border-radius: 5%;
		}

		.text-wrap {
			display: flex;
			flex-flow: column nowrap;
			justify-content: space-between;
			height: 3rem;
			flex: 1;

			font-size: 0;

			span {
				font-size: 12px;

			}

			.brand-wrap {
				flex: 1;
				// height: 17px;
			}

			.name-wrap {
				flex: 1;

				// height: 17px;
				.text-base {
					// font-size: 14px;
					// font-weight: bold
				}
			}

			.desc-wrap {
				// height: 35px;
				flex: 2;
				margin-bottom: 5px;
			}

			.price-wrap {
				// height: 17px;
				flex: 1;
				display: flex;
				flex-flow: row nowrap;
				align-items: center;

				.text-grey {
					text-decoration: line-through;
				}

				.text-theme3 {
					display: block;
					font-size: 18px;
					// margin-left: 5px;
				}
			}
		}


	}

	.group-detail-bottom {
		position: fixed;
		bottom: 0;
		left: 0;
		display: flex;
		flex-flow: row nowrap;
		background-color: white;
		height: 40px;
		width: 100%;
		overflow: hidden;
		text-align: center;
		border-top: 1px solid #e9e9e9;

		.group-detail-bottom-item {
			display: inline-block;
			font-size: 13px;
			width: 60%;
			line-height: 40px;
		}

		.group-detail-bottom-button {
			display: inline-block;
			font-size: 13px;
			color: white;
			width: 40%;
			line-height: 40px;
		}
	}
</style>
